import React from 'react';
import paypal from '../styles/images/de-pp-logo-100px.png';
import creditcards from '../styles/images/credit_cards.gif';
import { PsLink } from '../common/PsLink';
import { DataprivacyLink, TermsconditionsLink } from '../common/Links';
import {
  AboutMeLink,
  BlogLink,
  ContactLink,
  FaqLink,
  PressLink,
} from './Links';
import Lang from '../common/Lang';
import Newsletter from './Newsletter';
import PsSocialMedia from './PsSocialMedia';

export const Footer = () => (
  <div>
    <div className="custom-footer">
      <div className="container">
        <footer>
          <div className="row">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-offset-2 col-md-8">
                  <Newsletter galabel="footer" />
                </div>
              </div>
            </div>
            <div
              className="col-md-4 text-center middle-col"
              style={{ backgroundColor: 'white' }}
            >
              <p>&nbsp;Mo - Fr</p>
              <p>+49 911 36069244</p>
              <br />
              <FooterLink>
                <BlogLink>
                  <Lang de="blog" en="blog" />
                </BlogLink>
              </FooterLink>
              <FooterLink>
                <AboutMeLink>
                  <Lang de={`über mich`} en={`about`} />
                </AboutMeLink>
              </FooterLink>
              <FooterLink>
                <ContactLink>
                  <Lang de="Kontakt" en="Contact" />
                </ContactLink>
              </FooterLink>
              <FooterLink>
                <PressLink>
                  <Lang de="Presse" en="Press" />
                </PressLink>
              </FooterLink>
              <FooterLink>
                <PsLink to={'/imprint'}>
                  <Lang de="Impressum" en="Imprint" />
                </PsLink>
              </FooterLink>
              <FooterLink>
                <FaqLink>FAQ</FaqLink>
              </FooterLink>
              <FooterLink>
                <DataprivacyLink>
                  <Lang de="Datenschutz" en="Privacy Policy" />
                </DataprivacyLink>
              </FooterLink>
              <FooterLink>
                <TermsconditionsLink>
                  <Lang de="AGB" en="Terms & Conditions" />
                </TermsconditionsLink>
              </FooterLink>
            </div>
            <div className="col-md-4 text-center">
              <PsSocialMedia />

              <p style={{ marginTop: 78 }}>
                <img style={{ paddingBottom: 10 }} src={paypal} alt="pay pal" />
                <br />
                <br />
                <img src={creditcards} alt="credit cards" />
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-xs-12 text-center">
          <div style={{ fontSize: 14, margin: 40 }}>
            © 2014-2025 PaperShape UG (haftungsbeschränkt) &amp; Co. KG
          </div>
        </div>
      </div>
    </div>
  </div>
);

function FooterLink({ children }) {
  return <div>{children}</div>;
}
