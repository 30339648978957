import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FormSection } from 'redux-form';
import { trackCheckout } from '../../common/customga';
import OnlyEuOrder from '../../common/OnlyEuOrder';
import { getTextBySelectedLang } from '../../data/lang';
import PsInput from '../../common/inputs/PsInput';
import PsLabel from '../../common/inputs/PsLabel';
import PsCheckbox from '../../common/inputs/PsCheckbox';
import KnownSelect from '../../common/inputs/KnownSelect';
import ShippingCosts from '../../common/ShippingCosts';
import ShippingCountry from '../../common/ShippingCountry';
import DeliveryTime from '../../common/DeliveryTime';
import OrderSumGross from '../../common/OrderSumGross';
import Lang from '../../common/Lang';
import Address from './Address';
import ProductRow from '../../common/ProductRow';
import Vat from '../../common/Vat';
import { required, maxLength100, maxLength500, email } from '../../validation';
import {
  getOrderProductsEnhanced,
  orderFormName,
  getAlternativeShippingAddress,
  hasOnlyDigitalProducts,
  isOrderAllowed,
} from '../../state/order/orderSelectors';
import { DataprivacyLink, TermsconditionsLink } from '../../common/Links';
import { sendOrder } from '../../state/order/orderActions';
import GlobalMessages from '../../common/GlobalMessages';
import PsEmail from '../../common/inputs/PsEmail';
import VoucherInput from '../../common/VoucherInput';
import VoucherMessage from '../../common/VoucherMessage';
import OrderSubtotalSumGross from '../../common/OrderSubtotalSumGross';
import PsMeta from '../../common/PsMeta';

class OrderStep2 extends Component {
  componentDidMount() {
    trackCheckout(this.props.orderProducts);
  }

  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            `Bestellung Schritt 2 von 2`,
            `Order step 2 of 2`
          )}
          noindex
        />

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-header">
                <Lang de="Bestellung " en="Order " />

                <small>
                  <Lang de={`Schritt 2 von 2`} en={`step 2 of 2`} />
                </small>
              </h1>
              <p className="alert alert-warning">
                Wir sind vom 20.12. bis zum 1.1. im Urlaub 🎅 Wir verschicken
                wieder ab dem 2.1.2025 🎄
              </p>
            </div>
          </div>

          <form
            onSubmit={this.props.handleSubmit((formValues) =>
              this.props.dispatch(sendOrder(formValues)).then(() => {
                this.props.destroy();
                this.props.history.push(
                  '/' + this.props.match.params.lang + '/ordersuccessful/'
                );
              })
            )}>
            {this.props.hasOnlyDigitalProducts ? (
              <div
                className="alert alert-info"
                style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Lang
                  de={`Die Angabe der Adresse ist aus steuerlichen Gründen notwendig. Die digitale Vorlage wird nach Zahlungseingang versendet.`}
                  en={`The address is necessary for tax reasons. The digital template will be sent after receipt of payment.`}
                />
              </div>
            ) : (
              ''
            )}

            <h3>
              <Lang de="Persönliche Daten" en="Personal details" />
            </h3>

            <Field
              name="name"
              label={getTextBySelectedLang('Vorname', 'Firstname')}
              component={PsInput}
              validate={[required, maxLength100]}
            />

            <Field
              name="surname"
              label={getTextBySelectedLang('Nachname', 'Surname')}
              component={PsInput}
              validate={[required, maxLength100]}
            />

            <Field
              name="email"
              label={'E-Mail'}
              component={PsEmail}
              validate={[required, maxLength100, email]}
            />

            <h4>
              <Lang
                de="Liefer- und Rechnungsanschrift"
                en="Shipping and billing address"
              />
            </h4>

            <FormSection name="billingAddress">
              <Address />
            </FormSection>

            {!this.props.orderAllowed && <OnlyEuOrder />}

            <Field
              name="alternativeShippingAddress"
              label={
                <Lang
                  de="Ich möchte eine abweichende Lieferanschrift angeben."
                  en="I want to choose another shipping address"
                />
              }
              component={PsCheckbox}
            />

            {this.props.alternativeShippingAddress && (
              <FormSection name="shippingAddress">
                <Field
                  name="name"
                  label={getTextBySelectedLang('Nachname', 'Name')}
                  component={PsInput}
                  validate={[required, maxLength100]}
                />

                <Field
                  name="surname"
                  label={getTextBySelectedLang('Vorname', 'Surname')}
                  component={PsInput}
                  validate={[required, maxLength100]}
                />

                <Address />
              </FormSection>
            )}

            <Field
              name="note"
              label={<Lang de="Mitteilung an mich" en="Send me a message" />}
              placeholder={getTextBySelectedLang(
                'z.B. ob die Sendung ohne Rechnung oder mit einer neutralen Verpackung verschickt werden soll.',
                'e.g. if the order should be sent without an invoice or in a neutral package'
              )}
              component={PsInput}
              validate={[maxLength500]}
            />

            <Field
              name="known"
              label={getTextBySelectedLang(
                'Woher kennst du mich?',
                'How did you find out about me?'
              )}
              component={({ input, label, children, meta }) => (
                <PsLabel label={label} meta={meta}>
                  <KnownSelect input={input} className="form-control" />
                </PsLabel>
              )}
            />

            <Field
              name="newsletter"
              label={getTextBySelectedLang(
                'Ich möchte über neue Modelle informiert werden.',
                'I would like to be informed about new models.'
              )}
              component={PsCheckbox}
            />

            <Field
              name="termsConditions"
              label={
                <Lang
                  de={
                    <span>
                      Ich habe die{' '}
                      <TermsconditionsLink>
                        Allgemeinen Geschäftsbedingungen
                      </TermsconditionsLink>{' '}
                      und die{' '}
                      <DataprivacyLink>Datenschutzerklärung</DataprivacyLink>{' '}
                      gelesen und erkenne sie hiermit an.
                    </span>
                  }
                  en={
                    <span>
                      I have read the{' '}
                      <TermsconditionsLink>
                        Terms & Conditions
                      </TermsconditionsLink>{' '}
                      and the <DataprivacyLink>Privacy Policy</DataprivacyLink>{' '}
                      and accept them.
                    </span>
                  }
                />
              }
              component={PsCheckbox}
              validate={[required]}
            />

            <Field
              name="rightOfWithdrawal"
              label={
                <Lang
                  de={
                    <span>
                      Ich nehme das 14-tätige{' '}
                      <TermsconditionsLink>Widerrufrecht</TermsconditionsLink>{' '}
                      für den Kauf von Bastelsets zur Kenntnis. Bei Kauf von
                      digitalen Vorlagen stimme ich der Ausführung des
                      Kaufvertrags vor Ablauf der Widerrufsfrist ausdrücklich
                      zu. Ich habe zur Kenntnis genommen, dass mein
                      Widerrufsrecht in diesem Fall mit Beginn des Vertrags
                      erlischt.
                    </span>
                  }
                  en={
                    <span>
                      I agree with the 14-days{' '}
                      <TermsconditionsLink>
                        Cancellation right
                      </TermsconditionsLink>
                      .
                    </span>
                  }
                />
              }
              component={PsCheckbox}
              validate={[required]}
            />

            <h3>
              <Lang de="Liefergegenstand" en="Shipped item" />
            </h3>

            {this.props.orderProducts.map((product) => (
              <ProductRow
                key={`${product.id}${product.variantKey}`}
                {...this.props}
                product={product}
                readonly={true}
              />
            ))}

            <div>
              <div className="form-group">
                <label>
                  <Lang de="Zwischensumme" en="Subtotal" />
                </label>
                <div className="form-control-static">
                  <OrderSubtotalSumGross />
                </div>
              </div>
              <div className="form-group">
                <label>
                  {getTextBySelectedLang('Versandkosten', 'Shipping cost')}{' '}
                  <ShippingCountry />
                </label>
                <p className="form-control-static">
                  <ShippingCosts />
                </p>
              </div>
              <div className="form-group">
                <label>{getTextBySelectedLang('Gesamtpreis', 'Total')}</label>
                <p className="form-control-static">
                  <strong>
                    <OrderSumGross preorder />
                  </strong>{' '}
                  <Vat />
                  <br />
                  {this.props.hasOnlyDigitalProducts ? (
                    <small>
                      <Lang
                        de={`Die digitale Vorlage wird nach Zahlungseingang versendet`}
                        en={`The digital template will be sent after receipt of payment`}
                      />
                    </small>
                  ) : (
                    <small>
                      <DeliveryTime />
                    </small>
                  )}
                  <br />
                  <br />
                </p>
              </div>
            </div>

            <VoucherInput />
            <VoucherMessage />

            {!this.props.orderAllowed && <OnlyEuOrder />}

            <GlobalMessages />

            <div className="row">
              <div className="col-md-4">
                <button
                  id="buynow"
                  type="submit"
                  disabled={!this.props.orderAllowed}
                  className="btn btn-primary btn-lg btn-block jetzt-kaufen"
                  style={{ marginBottom: 20 }}>
                  <Lang de="Jetzt kaufen" en="Buy now" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderProducts: getOrderProductsEnhanced(state),
    alternativeShippingAddress: getAlternativeShippingAddress(state),
    hasOnlyDigitalProducts: hasOnlyDigitalProducts(state),
    orderAllowed: isOrderAllowed(state),
  };
};

const Connected = connect(mapStateToProps)(OrderStep2);

export default reduxForm({
  form: orderFormName,
  destroyOnUnmount: false,
})(Connected);
