import React, { Component } from 'react';
import anastasiabaron from '../styles/images/papershape-anastasia-baron.jpg';
import Lang from '../common/Lang';
import { getTextBySelectedLang } from '../data/lang';
import Newsletter from '../common/Newsletter';
import PsMeta from '../common/PsMeta';

export default class AboutMe extends Component {
  render() {
    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang('Über mich', 'about me')}
          description={getTextBySelectedLang(
            'Hier erfahrt ihr mehr über mich und meine Arbeit.',
            'Here you can find out more about me and my work.'
          )}
        />

        <div>
          <div className="container">
            <div className="row" style={{ marginTop: 60 }}>
              <div className="col-sm-6">
                <img
                  src={anastasiabaron}
                  alt="Anastasia Baron"
                  className="img-responsive"
                />
              </div>
              <div className="col-sm-6">
                <div style={{ margin: '50px 0 0' }} className="hidden-xs" />
                <h1 style={{ marginBottom: 30 }}>
                  <Lang de="Über mich" en="about me" />
                </h1>
                
                <p style={{ margin: '40px 0 15px' }}>
                  <Lang
                    de="Hi, ich bin Anastasia Baron. Ich bin Papierkünstlerin und habe aus Versehen BWL studiert. PaperShape ist 2014 aus einem DIY Blogpost entstanden."
                    en="Hi, I'm Anastasia Baron. I'm a paper artist and accidentally studied business administration. PaperShape was created in 2014 from a DIY blog post."
                  />
                </p>
                <p style={{ marginBottom: 15 }}>
                  <Lang
                    de="        
                    Für mich geht’s bei Papierkunst nicht um Perfektion, sondern ums Machen. 

Mit Papershape möchte ich dich dazu ermutigen, einfach loszulegen – ohne den Druck, dass alles perfekt sein muss. Papier ist ein super Medium dafür – simpel, für alle zugänglich und voller kreativer Möglichkeiten.

Ich selbst bin noch auf dieser Reise. Ich will mich im Machen verlieren, nicht alles kontrollieren, nicht immer nach Perfektion streben. Einfach ausprobieren, schauen, was passiert, und den Spaß am Prozess wiederfinden. Und genau das möchte ich mit dir teilen – zeigen, dass Kunst nicht kompliziert sein muss, sondern vor allem eins: frei.

Papershape steht für Leichtigkeit, Spielen und Loslassen. Also: Schnapp dir eine Schere, Papier und lass uns gemeinsam einfach loslegen!"
                    en="For me, paper art is not about perfection, it's about making.

With Papershape, I want to encourage you to just get started - without the pressure that everything has to be perfect. Paper is a great medium for this - simple, accessible to all and full of creative possibilities.

I'm still on this journey myself. I want to lose myself in making, not control everything, not always strive for perfection. Just try things out, see what happens and rediscover the fun in the process. And that's exactly what I want to share with you - to show that art doesn't have to be complicated, but one thing above all: free.

Papershape stands for lightness, play and letting go. So: grab a pair of scissors, some paper and let's just get started together!"
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="section grey-section">
            <div className="container">
              <div className="row text-center">
                <div className="row">
                  <div className="col-md-offset-2 col-md-8">
                    <Newsletter galabel="aboutme" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row" style={{ marginBottom: 20, marginTop: 0 }}>
              <div className="col-xs-12">
                <div className="text-center">
                  <h2>
                    <Lang de="Meine Werte" en="My values" />
                  </h2>
                  
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <h3 style={{ marginTop: 30 }}>
                  <Lang de="Ich sein" en="Being me" />
                </h3>
                <p>
                  <Lang
                    de="Erinnerst du dich, wie wir als Kinder einfach drauflos gebastelt haben, ohne uns Gedanken zu machen, ob es „gut“ aussieht? Wie wir uns im Moment verloren haben? Genau das will ich wieder – einfach Loslassen und im Jetzt mit meinem Papier sein.
 
"
                    en="Do you remember how, as children, we simply tinkered away without worrying about whether it looked „good“? How we lost ourselves in the moment? That is exactly what I want to do again - just let go and be in the now with my paper."
                  />
                </p>
                <p>
                  <Lang
                    de="  "
                    en=""
                  />
                </p>
                <Lang de="" en="" />
                <h3 style={{ marginTop: 30 }}>
                  <Lang de="Schönheit im Einfachen" en="Beauty in simplicity" />
                </h3>
                <p>
                  <Lang
                    de="Oft übersehen wir, wie viel Schönheit in den einfachen Dingen liegt. Bei meinen Papierskulpturen experimentiere ich mit einfachen geometrischen Formen, um herauszufinden, wie weit ich die Form reduzieren kann, um die Balance zwischen Ästethik und Einfachheit zu wahren.
                    
                    "
                    en="We often overlook how much beauty there is in simple things. In my paper sculptures, I experiment with simple geometric shapes to find out how far I can reduce the form to maintain the balance between aesthetics and simplicity."
                  />
      
                </p>
                
              </div>
              <div className="col-sm-6">
                <h3 style={{ marginTop: 30 }}>
                  <Lang de="Loslassen" en="Letting go" />
                </h3>
                <p>
                  <Lang
                    de="Papier ist wandelbar – es kann gefaltet, geschnitten, gerissen oder geschichtet werden. Ich möchte dir zeigen, dass Kreativität im Loslassen von Vorstellungen und im Mut zum Neudenken liegt. Erschaffe mit deinen Händen dein eigenes Kunstwerk mit deiner Geschichte."
                    en="Paper is changeable - it can be folded, cut, torn or layered. I want to show you that creativity lies in letting go of ideas and having the courage to rethink. Use your hands to create your own work of art with your own story."
                  />
                </p>
                
                
                <Lang de="" en="" />
                <h3 style={{ marginTop: 30 }}>
                  <Lang de="Du kannst das auch" en="You can do it too" />
                </h3>
                <p>
                  <Lang
                    de="Für mich ist das Erschaffen mit den Händen ein natürlicher Teil des Menschseins. Es fühlt sich besonders an, wenn durch unsere eigene Kreativität und Mühe etwas Neues entsteht. Deshalb sollte Basteln nicht nur etwas für Kinder sein – es ist etwas, das uns allen guttut.
                    
                    "
                    en="For me, creating with my hands is a natural part of being human. It feels special when something new is created through our own creativity and effort. That's why crafting shouldn't just be for children - it's something that's good for all of us."
                  />
      
                </p>

              </div>
            </div>
          </div>
        </div>
        <img
          src="https://vg07.met.vgwort.de/na/a252c82984d94d3fbad0468506c3087d"
          width="1"
          height="1"
          alt=""
        />
      </div>
    );
  }
}
