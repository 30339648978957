import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { PsLink } from '../common/PsLink';
import PsProductListByCategory from '../common/PsProductListByCategory';
import { getTextBySelectedLang, selectedLang } from '../data/lang';
import Lang from '../common/Lang';
import { Grid, Row, Col } from 'react-bootstrap';
import { getBlogPosts } from '../state/blog/blogSelectors';
// import papershapeohnekleben from '../styles/images/papershape-ohne-kleben.gif';
import papershapeanastasiabaron from '../styles/images/papershape-anastasia-baron.jpg';
import publiciconsLeaf from '../styles/images/icons/publicicons-Leaf.png';
import publiciconsTree from '../styles/images/icons/publicicons-Tree.png';
import publiciconsPin from '../styles/images/icons/publicicons-Pin.png';
import { addFolder } from './blog/BlogPost';
import KnownFrom from './KnownFrom';
import PsImage from '../common/PsImage';
import PsMeta from '../common/PsMeta';

class Start extends Component {
  render() {
    const mainimage = {
      filename: {
        de: 'papercraft-katze-basteln.jpg',
        en: 'papercraft-katze-basteln.jpg',
      },
      alt_text: {
        de: 'Papercraft Katze Simba basteln - PaperShape',
        en: 'Papercraft cat Simba - PaperShape',
      },
    };

    return (
      <div>
        <PsMeta
          title={getTextBySelectedLang(
            'Shop für 3D Deko aus Papier zum Selbermachen',
            'Faux taxidermy from paper'
          )}
          description={getTextBySelectedLang(
            'Shoppe die schönsten DIY Ideen aus Papier als Geschenke oder zum Aufhübschen deiner Wohnung mit unseren Bastelsets und digitalen Vorlagen.',
            'Faux taxidermy heads made out of paper. 100% success garanty ✓ More than 10.000 happy customers. Worldwide shipping.'
          )}
          socialdescription={getTextBySelectedLang(
            'Entdecke die schönsten digitalen Vorlagen und Bastelsets aus Papier für Erwachsene: 3D Tierköpfe, Deko aus Papier und Papierblumen. ',
            'Faux taxidermy heads made out of paper.'
          )}
          socialimages={[mainimage.filename[selectedLang]]}
        />

        <div>
          <div className="section" style={{ paddingBottom: 0 }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-offset-1 col-lg-10 text-center">
                  <h1 className="startseite-header">
                    <Lang de="Shop" en="SHOP" />
                  </h1>

                  <PsImage image={mainimage} style={{ marginTop: 40 }} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <KnownFrom />

        <hr style={{ marginTop: 0 }} />

        <div className="section" id="produkte">
          <PsProductListByCategory open />
        </div>

        <Grid>
          <Row style={{ marginTop: '30px' }}>
            <PsLink to={'/blog/'} disableStyles>
              <h2 className="text-center" style={{ marginBottom: '30px' }}>
                <Lang de="DIY Ideen aus Papier" en="DIY paper crafts" />
              </h2>
              {this.props.blogPosts.slice(0, 4).map((blogPost, index) => {
                const imageFolder = 'content/' + blogPost.imageFolder + '/';
                const addImageFolder = addFolder(imageFolder);
                return (
                  <div key={blogPost.url}>
                    <Col
                      xs={6}
                      sm={3}
                      className={cn({ 'hidden-xs': index > 1 })}
                    >
                      <PsImage
                        image={addImageFolder(blogPost.image_main)}
                        format="600x900"
                      />
                    </Col>
                  </div>
                );
              })}
              <div className="clearfix" />
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                  marginTop: '20px',
                }}
              >
                <div className="btn btn-default">
                  <Lang
                    de="zur den DIY Ideen aus Papier"
                    en="see DIY paper crafts"
                  />
                </div>
              </div>
            </PsLink>
          </Row>
        </Grid>

        <div className="section grey-section">
          <div className="container">
            <div className="row text-center">
              <div className="col-xs-12 col-md-4">
                <img style={{ width: 30 }} alt="leaf" src={publiciconsLeaf} />
                <h3>
                  <Lang de="NATURPRODUKT" en="NATURAL PRODUCT" />
                </h3>
                <p>
                  <Lang
                    de="Die Faserstoffe unserer Qualitätspapiere bestehen aus 100% Altpapier."
                    en="The fibres of our high-quality paper constist of 100%  recovered paper."
                  />
                </p>
              </div>
              <div className="col-xs-12 col-md-4">
                <img style={{ width: 30 }} alt="tree" src={publiciconsTree} />
                <h3>
                  <Lang de="RESSOURCENSCHONEND" en="RESOURCE-SAVING" />
                </h3>
                <p>
                  <Lang
                    de="Unser Papiere sind mit dem Blauen Engel und FSC-Label zertiﬁziert."
                    en="Our papers are certified with the German 'Blauer Engel' (Blue Angel) and the FSC-label."
                  />
                </p>
              </div>
              <div className="col-xs-12 col-md-4">
                <img style={{ width: 30 }} alt="pin" src={publiciconsPin} />
                <h3>
                  <Lang de="MADE IN GERMANY" en="MADE IN GERMANY" />
                </h3>
                <p>
                  <Lang
                    de="Unsere gesamte Wertschöpfung findet in Deutschland statt."
                    en="Our entire value chain is located in Germany and starts with the design in Bavaria, Nürnberg"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <img
                  className="img-responsive"
                  src={papershapeanastasiabaron}
                  alt="Anastasia Baron"
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <h2 style={{ marginBottom: 40 }}>
                  <Lang
                    de="Wer ist der kreative Kopf hinter PaperShape?"
                    en="Who designs the low poly wall deco from paper?"
                  />
                </h2>
                <p>
                  <Lang
                    de="Ich bin Anastasia Baron. Ich habe aus Versehen BWL studiert. Seit 2014 arbeite ich als Papierkünstlerin für End- und Geschäftskunden."
                    en="I'm Anastasia and I studied Business Administration by mistake. Since 2014 I am working for customers and business clients as a paper designer."
                  />
                </p>
                
                <p>
                  <Lang
                    de={
                      <span>
                        <PsLink to={'/aboutme'}>Lies hier mehr zu meiner Geschichte</PsLink> 
                      </span>
                    }
                    en={
                      <span>
                        <PsLink to={'/aboutme'}>Continue reading</PsLink> if you
                        want to know more about me.
                      </span>
                    }
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blogPosts: getBlogPosts(state),
  };
};

export default connect(mapStateToProps)(Start);
